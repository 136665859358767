<!--
 * @Author: 墨凡 oncwnuN3VU3V55c1lqO_6llf5DtU@git.weixin.qq.com
 * @Date: 2022-08-22 10:35:43
 * @LastEditors: zll zll1217168026@163.com
 * @LastEditTime: 2022-10-10 09:29:12
 * @FilePath: \ding-lims\src\views\Statistics\DataSuppliers.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="DataSuppliers">
    <van-nav-bar
      title="供应商分析"
      left-text="返回"
      right-text="筛选"
      left-arrow   
      @click-left="back"
      @click-right="chooseshow = true"
    />
    <van-row class="mt10">
      <van-col span='1'></van-col>
      <van-col span="">{{params.start_time+'~'+params.end_time}}</van-col>     
    </van-row>
    <ve-table class="item mt10"
      :columns="columns"
      :table-data="dataList"
      :border-around="true"
      :border-x="true"
      :border-y="true" />
    <van-pagination class="mt10" v-model="page.currentPage" :total-items="page.total" :items-per-page='page.offset' :show-page-size="5" v-if="page.show" @change='pageChange' force-ellipses >
      <template #prev-text>
        <van-icon name="arrow-left" />
      </template>
      <template #next-text>
        <van-icon name="arrow" />
      </template>
      <template #page="{ text }" >{{ text }}</template>
    </van-pagination>
    <van-popup round v-model="chooseshow" position="top">
      <van-field name="start_time"
        v-model="params.start_time"
        label="开始时间"
        @click-input="StimeShow"
        readonly
        clickable/>
      <van-field name="end_time"
        v-model="params.end_time"
        label="结束时间"
        @click-input="EtimeShow"
        readonly 
        clickable/>
      <van-field name="supplier_code"
        v-model="params.supplier_code"
        label="供应商"
        @click-input="suppliersShow = true"
        clearable />
      <!-- <van-cell title="供应商">
        <template #default>
          <van-search v-model="params.supplier_code"
            input-align="right"
            left-icon="" 
            placeholder="请输入供应商关键词" 
            @input="getSupplier"/>
          <div class="slist">
            <van-cell v-for="item in suppliers" :key="item.supplier_code" :title="item.supplier_name" @click="toCode(item.supplier_code)" />
          </div>
        </template>
      </van-cell> -->
      <van-button class="btn" round block size="small" type="primary" @click="toSure()">确定</van-button>
    </van-popup>
    <!-- 选择供应商 -->
    <!-- 产品搜索 -->
    <van-popup v-model="suppliersShow" position="bottom" round :style="{ height: '80%' }">
      <van-search v-model="params.supplier_code" placeholder="请输入供应商关键词" :autofocus="autofocus" @input="getSupplier"/>
      <div class="slist">
        <van-cell v-for="item in suppliers" :key="item.supplier_code" :title="item.supplier_name" @click="toCode(item.supplier_code)" />
      </div>
    </van-popup>
    <!-- <van-action-sheet v-model:show="suppliersShow" :actions="actions" @select="onSelect" /> -->
    <!-- 日期选择 --> 
    <van-popup v-model="timeShow" position="bottom">
      <van-datetime-picker
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="timeConfirm"
        @cancel="timeShow = false"
      />
    </van-popup>
    <div class="import-excel" title="导出excel" v-if="isPhone == false" @click="ToExcel" v-track="{triggerType:'click', title:'导出供应商合格率',currentUrl: $route.path,behavior: '导出供应商合格率', duration: new Date().getTime()}">            
      <van-icon  name="down" color="#fff" size="26" style="line-height: 50px;" />
    </div>
    <van-cell-group style="display: none;">
      <div id="exportData">
        <ve-table style="" id="table" ref="filterTable" :columns="dcColumns" :table-data="tableAll" :border-x="true" scrollWidth="720px" :border-y="true" /> 
      </div>      
    </van-cell-group>
</div>
</template>
<script>
import Vue from 'vue'
import { NavBar, Row, Col, Cell, Button, Popup, Field, DatetimePicker, Icon, Pagination, Search } from 'vant'
import { VePagination } from "vue-easytable"
import exportTableUtil from '@/utils/exportTableUtil.js'
import XLSX from 'xlsx'

Vue.use(NavBar)
Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(Popup)
Vue.use(Field)
Vue.use(DatetimePicker)
Vue.use(VePagination)
Vue.use(Icon)
Vue.use(Pagination)
Vue.use(Cell)
Vue.use(Search)

export default {  
  name: 'DataSuppliers',
  components: {},
  data() {    
    return {
      isPhone: this.$common.isMobilePhone(),    
      params: {
        start_time: this.$common.formatDate(new Date(2022, 0, 1)),
        end_time: this.$common.formatDate(new Date()),
      },
      page: {
        show: false,
        currentPage: 1,
        total: 0,
        page: 1,
        offset: 8,
      },
      dataStatus: '',
      dataList: [],
      tableAll: [],
      columns: [        
        { field: "supplier_name", key: "a", title: "供应商名称", align: "center"},
        { field: "hgl", key: "b", title: "合格率(%)", align: "center", width: "80px" },
        { field: "check_num", key: "c", title: "检测次数", align: "center", width: "60px" },
        { field: "qualified_num", key: "d", title: "合格次数", align: "center", width: "60px" },
      ],
      dcColumns: [
        { field: "supplier_code", key: "a", title: "供应商编码", align: "center", width: "100px" },
        { field: "supplier_name", key: "b", title: "供应商名称", align: "center", width: "180px"},
        { field: "check_num", key: "c", title: "检测次数", align: "center", width: "80px"},
        { field: "qualified_num", key: "d", title: "合格次数", align: "center", width: "80px" },
        { field: "hgl", key: "e", title: "合格率(%)", align: "center", width: "50px" },
        { field: "", key: "f", title: "", align: "", width: "" }
      ],
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(),
      suppliers: [],
      chooseshow: false,
      suppliersShow: false,
      timeShow: false
    }
  },
  created: function () {
    this.getData()
    this.getAll()
  },
  mounted() { },
  methods: {
    // 获取检测类型
    getData() {
      this.$get('statistics/get-check-supplier-sta', 'api', {
        ...this.params,
        ...this.page
      }).then(res => {
        this.dataList = res.data.list        
        this.page.total = Number(res.data.count)
        if (this.page.total > this.page.offset) {
          this.page.show = true
        }
      }, err => {
        this.dataList = []
        this.page.total = 0
        this.$notify({ type: 'warning', message: err.message })
      })
    },
    pageChange(val) {
      this.page.page = val
      this.getData()
    },
    StimeShow() {
      this.timeShow = true
      this.dateStatus = 'start'
    },
    EtimeShow() {
      this.timeShow = true
      this.dateStatus = 'end'
    },
    // 日期确认
    timeConfirm(time) {
      if (this.dateStatus === 'start') {
        this.params.start_time = this.$common.formatDate(time)
      } else if (this.dateStatus === 'end') {
        this.params.end_time = this.$common.formatDate(time)
      }
      this.timeShow = false
    },
    getSupplier(val) {
      if (val) {
        this.$get('inspect/get-supplier-list', 'api', {
          page: 1,
          offset: 10,
          supplier_name: val
        }).then(res => {
        this.suppliers = res.data.list
        }, err => {
          this.suppliers = []
          this.$notify({ type: 'warning', message: err.message })
        })
      } else {
        this.$notify({ type: 'warning', message: '请输入搜索关键词' })
      }
    },
    toCode(val) {
      this.params.supplier_code = val
      this.suppliers = []
      this.suppliersShow = false
    },
    getAll() {
      var params = {
        start_time: this.params.start_time,
        end_time: this.params.end_time,
        page: 1,
        offset: 100
      }
      this.$get('statistics/get-check-supplier-sta', 'api', params).then(res => {
        this.tableAll = res.data.list       
      }, err => {
        this.tableAll = []
      })
    },
    // 导出excel
    ToExcel () {       
      let tbody = document.querySelector('#table tbody')
      var tr = tbody.getElementsByTagName('tr')
      tr.forEach(ele => {
        if(ele.className.indexOf('ve-table-body-tr') == -1){  
           tbody.removeChild(ele)         
        }
      })    
      let wb = XLSX.utils.table_to_book(document.querySelector('#table'))
      let elt = document.getElementById('exportData')  
      exportTableUtil.exportTable(elt, '供应商分析', '供应商分析', 1)
    },
    toSure() {
      this.page.page = 1
      this.page.currentPage = 1
      this.chooseshow = false
      this.getData()
      this.getAll()
    },
    back() {
      this.$router.push({ path: '/statistics' })
    }
  }
}
</script>

<style scoped>
.btn{
  width: 90%;
  margin: 20px 5%;
}
.ve-pagination{
  background: transparent;
}
.import-excel {
   width: 50px;
   height: 50px;
   background: #3d7cf3;
   position: fixed;
   bottom: 150px;
   z-index: 999;
   border-radius: 50%;
   text-align: center; 
   opacity: 0.8;
   right: 0; 
   cursor: pointer;
}
</style>
